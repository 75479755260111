// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.btn {

    padding-top: 0.5rem;

    padding-bottom: 0.5rem;

    padding-left: 1rem;

    padding-right: 1rem;

    font-weight: 700
}

.btn-sm {

    font-size: 0.875rem;

    line-height: 1.25rem
}

.btn-primary {

    --tw-bg-opacity: 1;

    background-color: rgb(2 132 199 / var(--tw-bg-opacity));

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}

.btn-primary:is(.dark *) {

    --tw-text-opacity: 1;

    color: rgb(209 213 219 / var(--tw-text-opacity))
}

.btn-primary:hover {

    --tw-bg-opacity: 1;

    background-color: rgb(56 189 248 / var(--tw-bg-opacity))
}

.btn-secondary {

    --tw-bg-opacity: 1;

    background-color: rgb(209 213 219 / var(--tw-bg-opacity));

    --tw-text-opacity: 1;

    color: rgb(31 41 55 / var(--tw-text-opacity))
}

.btn-secondary:is(.dark *) {

    --tw-bg-opacity: 1;

    background-color: rgb(75 85 99 / var(--tw-bg-opacity));

    --tw-text-opacity: 1;

    color: rgb(209 213 219 / var(--tw-text-opacity))
}

.btn-secondary:hover {

    --tw-bg-opacity: 1;

    background-color: rgb(229 231 235 / var(--tw-bg-opacity))
}

.btn-secondary:hover:is(.dark *) {

    --tw-bg-opacity: 1;

    background-color: rgb(107 114 128 / var(--tw-bg-opacity))
}

.btn-transparent {

    --tw-text-opacity: 1;

    color: rgb(31 41 55 / var(--tw-text-opacity))
}

.btn-transparent:is(.dark *) {

    --tw-text-opacity: 1;

    color: rgb(209 213 219 / var(--tw-text-opacity))
}

.btn-transparent:hover {

    --tw-text-opacity: 1;

    color: rgb(56 189 248 / var(--tw-text-opacity))
}

.btn-outline-secondary {

    border-width: 1px;

    --tw-border-opacity: 1;

    border-color: rgb(209 213 219 / var(--tw-border-opacity));

    background-color: transparent;

    font-weight: 600;

    --tw-text-opacity: 1;

    color: rgb(209 213 219 / var(--tw-text-opacity))
}

.btn-outline-secondary:hover {

    border-color: transparent;

    --tw-bg-opacity: 1;

    background-color: rgb(156 163 175 / var(--tw-bg-opacity));

    --tw-text-opacity: 1;

    color: rgb(255 255 255 / var(--tw-text-opacity))
}

/* Disabled */
.btn:disabled {

    --tw-bg-opacity: 1;

    background-color: rgb(209 213 219 / var(--tw-bg-opacity));

    --tw-text-opacity: 1;

    color: rgb(107 114 128 / var(--tw-text-opacity));

    opacity: 0.6
}
.btn:disabled:is(.dark *) {

    --tw-bg-opacity: 1;

    background-color: rgb(55 65 81 / var(--tw-bg-opacity));

    --tw-text-opacity: 1;

    color: rgb(107 114 128 / var(--tw-text-opacity))
}
.btn:disabled {

    cursor: not-allowed
}
`, "",{"version":3,"sources":["webpack://./src/components/common/Button.css"],"names":[],"mappings":"AACI;;IAAA,mBAA0B;;IAA1B,sBAA0B;;IAA1B,kBAA0B;;IAA1B,mBAA0B;;IAA1B;AAA0B;;AAI1B;;IAAA,mBAAc;;IAAd;AAAc;;AAId;;IAAA,kBAA+C;;IAA/C,uDAA+C;;IAA/C,oBAA+C;;IAA/C;AAA+C;;AAA/C;;IAAA,oBAA+C;;IAA/C;AAA+C;;AAI/C;;IAAA,kBAAiB;;IAAjB;AAAiB;;AAIjB;;IAAA,kBAAqE;;IAArE,yDAAqE;;IAArE,oBAAqE;;IAArE;AAAqE;;AAArE;;IAAA,kBAAqE;;IAArE,sDAAqE;;IAArE,oBAAqE;;IAArE;AAAqE;;AAIrE;;IAAA,kBAAmC;;IAAnC;AAAmC;;AAAnC;;IAAA,kBAAmC;;IAAnC;AAAmC;;AAInC;;IAAA,oBAAuC;;IAAvC;AAAuC;;AAAvC;;IAAA,oBAAuC;;IAAvC;AAAuC;;AAIvC;;IAAA,oBAAmB;;IAAnB;AAAmB;;AAInB;;IAAA,iBAAwE;;IAAxE,sBAAwE;;IAAxE,yDAAwE;;IAAxE,6BAAwE;;IAAxE,gBAAwE;;IAAxE,oBAAwE;;IAAxE;AAAwE;;AAIxE;;IAAA,yBAAgD;;IAAhD,kBAAgD;;IAAhD,yDAAgD;;IAAhD,oBAAgD;;IAAhD;AAAgD;;AAGpD,aAAa;AAET;;IAAA,kBAA+E;;IAA/E,yDAA+E;;IAA/E,oBAA+E;;IAA/E,gDAA+E;;IAA/E;AAA+E;AAA/E;;IAAA,kBAA+E;;IAA/E,sDAA+E;;IAA/E,oBAA+E;;IAA/E;AAA+E;AAC/E;;IAAA;AAAyB","sourcesContent":[".btn {\n    @apply font-bold py-2 px-4;\n}\n\n.btn-sm {\n    @apply text-sm;\n}\n\n.btn-primary {\n    @apply bg-sky-600 text-white dark:text-gray-300;\n}\n\n.btn-primary:hover {\n    @apply bg-sky-400;\n}\n\n.btn-secondary {\n    @apply bg-gray-300  text-gray-800 dark:bg-gray-600 dark:text-gray-300;\n}\n\n.btn-secondary:hover {\n    @apply bg-gray-200 dark:bg-gray-500;\n}\n\n.btn-transparent {\n    @apply text-gray-800 dark:text-gray-300;\n}\n\n.btn-transparent:hover {\n    @apply text-sky-400;\n}\n\n.btn-outline-secondary {\n    @apply font-semibold bg-transparent text-gray-300 border border-gray-300;\n}\n\n.btn-outline-secondary:hover {\n    @apply text-white bg-gray-400 border-transparent;\n}\n\n/* Disabled */\n.btn:disabled {\n    @apply bg-gray-300 text-gray-500 dark:bg-gray-700 dark:text-gray-500 opacity-60;\n    @apply cursor-not-allowed;\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
