// Imports
import ___CSS_LOADER_API_SOURCEMAP_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/sourceMaps.js";
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.rbc-month-view {
    border-radius: 0.375rem;
}

/* Dark Mode */
.dark .rbc-calendar {
    background-color: #1f2937;
    color: #e5e7eb;
  }

.dark .rbc-toolbar .rbc-btn-group > button {
    color: #e5e7eb
}

.dark .rbc-toolbar .rbc-btn-group > button[class=rbc-active],
.dark .rbc-toolbar .rbc-btn-group > button:hover,
.dark .rbc-toolbar .rbc-btn-group > button:focus {
    color: #1f2937
}

.dark .rbc-event {
    background-color: #3182ce;
    color: #e5e7eb;
}

.dark .rbc-day-bg {
    background-color: #2d3748;
}

.dark .rbc-off-range-bg {
    background-color: #4b5563 !important;
}

.dark .rbc-today {
    background-color: #9ca3af;
    color: #1f2937;
}

.dark .rbc-time-slot {
    border-color: #4a5568; 
}
`, "",{"version":3,"sources":["webpack://./src/components/leave/LeaveCalendar.css"],"names":[],"mappings":"AACI;IAAA;AAAiB;;AAGrB,cAAc;AACd;IACI,yBAAyB;IACzB,cAAc;EAChB;;AAEF;IACI;AACJ;;AAEA;;;IAGI;AACJ;;AAEA;IACI,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI,yBAAyB;AAC7B;;AAEA;IACI,oCAAoC;AACxC;;AAEA;IACI,yBAAyB;IACzB,cAAc;AAClB;;AAEA;IACI,qBAAqB;AACzB","sourcesContent":[".rbc-month-view {\n    @apply rounded-md;\n}\n\n/* Dark Mode */\n.dark .rbc-calendar {\n    background-color: #1f2937;\n    color: #e5e7eb;\n  }\n\n.dark .rbc-toolbar .rbc-btn-group > button {\n    color: #e5e7eb\n}\n\n.dark .rbc-toolbar .rbc-btn-group > button[class=rbc-active],\n.dark .rbc-toolbar .rbc-btn-group > button:hover,\n.dark .rbc-toolbar .rbc-btn-group > button:focus {\n    color: #1f2937\n}\n\n.dark .rbc-event {\n    background-color: #3182ce;\n    color: #e5e7eb;\n}\n\n.dark .rbc-day-bg {\n    background-color: #2d3748;\n}\n\n.dark .rbc-off-range-bg {\n    background-color: #4b5563 !important;\n}\n\n.dark .rbc-today {\n    background-color: #9ca3af;\n    color: #1f2937;\n}\n\n.dark .rbc-time-slot {\n    border-color: #4a5568; \n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {};
export default ___CSS_LOADER_EXPORT___;
